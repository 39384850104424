import { store } from "@/store/store";
import { rol } from "@/shared/dtos/rol";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";
import { rol_permiso } from "@/shared/dtos/rol_permiso";
import { permiso } from "@/shared/dtos/permiso";

@Module({
  namespaced: true,
  name: "rolModule",
  store,
  dynamic: true,
})
class rolModule extends VuexModule {
  public roles: rol[] = [];
  public rol: rol = new rol();
  public permisos_rol: permiso[] = [];
  public ficha_rol:rol_permiso= new rol_permiso();

  @Action({ commit: "onGetroles" })
  public async getroles() {
    return await ssmHttpService.get(API.rol);
  }

  @Action({ commit: "onGetrol" })
  public async getrol(id: any) {
    return await ssmHttpService.get(API.rol + "/" + id);
  }
  @Action({ commit: "onGetrol_permiso" })
  public async getrol_ficha(id: any) {
    return await ssmHttpService.get(API.rol + "/ficha/" + id);
  }

  @Action({ commit: "onGetrol_permisos" })
  public async getrol_permisos(id: any) {
    return await ssmHttpService.get(API.permiso + "/permisos_rol/" + id);
  }

  @Action
  public async guardarrol(rol: rol) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.rol, rol.toJson());
  }

  @Action
  public async guardarrol_permiso(rol: rol_permiso) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    return await ssmHttpService.post(API.rol + "/ficha", rol.toJson());
  }

  @Action
  public async modificarrol(rol: rol) {
    return await ssmHttpService.put(API.rol + "/" + rol.id, rol);
  }

  @Action
  public async modificarrol_permiso(rol: rol_permiso) {
    return await ssmHttpService.put(API.rol + "/ficha", rol);
  }

  @Action
  public async eliminarrol(rol: rol) {
    return await ssmHttpService.delete(API.rol + "/" + rol.id, null, false);
  }

  @Action
  public async eliminarrol_permiso(id: number) {
    return await ssmHttpService.delete(API.rol + "/" + id, null, false);
  }

  @Mutation
  public onGetroles(res: rol[]) {
    this.roles = res ? res.map((x) => new rol(x)) : [];
  }

  @Mutation
  public onGetrol(res: rol) {
    this.rol = new rol(res);
  }
  @Mutation
  public onGetrol_permisos(res: permiso[]) {
    this.permisos_rol = res ? res.map((x) => new permiso(x)) : [];
  }

  @Mutation
  public onGetrol_permiso(res: rol_permiso) {
    this.ficha_rol = new rol_permiso(res);
  }
  
}
export default getModule(rolModule);
