





















































































import { permiso } from "@/shared/dtos/permiso";
import { rol_permiso } from "@/shared/dtos/rol_permiso";
import { MessageService } from "@/shared/services/message-service";
import rolModule from "@/store/modules/rol-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class RolesFromulario extends Vue {
  async created() {
    await rolModule.getrol_ficha(this.$route.params.id);
  }

  public get datasource() {
    return rolModule.ficha_rol;
  }

  public get headers() {
    return [
      {
        text: "Nombre/Pantalla",
        value: "nombre",
      },
      ,
      { text: "Permiso menú", align: "start", value: "permiso_menu" },
      { text: "Permiso lectura", align: "start", value: "permiso_read" },
      { text: "Permiso escritura", align: "start", value: "permiso_write" },
      {
        text: "Permiso actualización",
        align: "start",
        value: "permiso_update",
      },
      { text: "Permiso eliminación", align: "start", value: "permiso_delete" },
      {
        text: "Permiso global de datos",
        align: "start",
        value: "permiso_global",
      },
    ];
  }

  public update_permiso(evento: boolean, permiso: string) {
    if (evento) {
      var indice = this.datasource.permisos_str.findIndex((x) => x === permiso);
      var existe = indice > -1;
      if (!existe) {
        this.datasource.permisos_str.push(permiso);
      }
    } else {
      var indice = this.datasource.permisos_str.findIndex((x) => x === permiso);
      var existe = indice > -1;
      if (existe) {
        this.datasource.permisos_str.splice(indice, 1);
      }
    }
  }

  public is_new() {
    return Number.parseInt(this.$route.params.id) === -1;
  }

  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        var dto = new rol_permiso();
        dto.rol = this.datasource.rol;
        dto.rol.id = Number.parseInt(this.$route.params.id);
        dto.permisos = this.datasource.permisos_str.map(
          (x) => new permiso({ id: 0, nombre: x, id_rol: dto.rol.id })
        );
        if (this.is_new()) {
          rolModule.guardarrol_permiso(dto).then(() => this.cancelar());
        } else {
          rolModule.modificarrol_permiso(dto).then(() => this.cancelar());
        }
      }
    });
  }

  public cancelar() {
    this.$router.back();
  }

  eliminar() {
    MessageService.confirm(
      "¿Seguro que desea eliminar el grupo?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          rolModule
            .eliminarrol_permiso(Number.parseInt(this.$route.params.id))
            .then(() => this.cancelar());
        }
      }
    );
  }
}
