import { BaseDto } from "@/shared/dtos/base-dto";
import { permiso } from "@/shared/dtos/permiso";
import { rol } from "@/shared/dtos/rol";
import { mantenimiento_permiso_cheked } from "./mantenimiento_permisos_cheked";

export class rol_permiso extends BaseDto {
  public rol!: rol;
  public permisos!: permiso[];
  public tabla!: mantenimiento_permiso_cheked[];
  public permisos_str!:string[];
}
