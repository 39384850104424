var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MaterialCard',{staticClass:"m-shadow mt-8 pa-1 m-rouded",attrs:{"color":"white"}},[_c('template',{slot:"heading"},[_c('Xbtn',{attrs:{"text":"","color":"green","permisos":['write_roles', 'update_roles']},on:{"click":function($event){return _vm.aceptar()}}},[_vm._v("Aceptar")]),_c('Xbtn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.cancelar()}}},[_vm._v("Cancelar")]),(!_vm.is_new())?_c('Xbtn',{attrs:{"text":"","color":"red","permiso":"delete_roles"},on:{"click":function($event){return _vm.eliminar()}}},[_vm._v("Eliminar")]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Nombre del rol"},model:{value:(_vm.datasource.rol.nombre),callback:function ($$v) {_vm.$set(_vm.datasource.rol, "nombre", $$v)},expression:"datasource.rol.nombre"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.datasource.tabla,"items-per-page":1000},scopedSlots:_vm._u([{key:"item.permiso_read",fn:function(ref){
var item = ref.item;
return [(item.permiso_read !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_read)}},model:{value:(item.permiso_read_checked),callback:function ($$v) {_vm.$set(item, "permiso_read_checked", $$v)},expression:"item.permiso_read_checked"}}):_vm._e()]}},{key:"item.permiso_write",fn:function(ref){
var item = ref.item;
return [(item.permiso_write !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_write)}},model:{value:(item.permiso_write_checked),callback:function ($$v) {_vm.$set(item, "permiso_write_checked", $$v)},expression:"item.permiso_write_checked"}}):_vm._e()]}},{key:"item.permiso_update",fn:function(ref){
var item = ref.item;
return [(item.permiso_update !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_update)}},model:{value:(item.permiso_update_checked),callback:function ($$v) {_vm.$set(item, "permiso_update_checked", $$v)},expression:"item.permiso_update_checked"}}):_vm._e()]}},{key:"item.permiso_delete",fn:function(ref){
var item = ref.item;
return [(item.permiso_delete !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_delete)}},model:{value:(item.permiso_delete_checked),callback:function ($$v) {_vm.$set(item, "permiso_delete_checked", $$v)},expression:"item.permiso_delete_checked"}}):_vm._e()]}},{key:"item.permiso_menu",fn:function(ref){
var item = ref.item;
return [(item.permiso_menu !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_menu)}},model:{value:(item.permiso_menu_checked),callback:function ($$v) {_vm.$set(item, "permiso_menu_checked", $$v)},expression:"item.permiso_menu_checked"}}):_vm._e()]}},{key:"item.permiso_global",fn:function(ref){
var item = ref.item;
return [(item.permiso_global !== '')?_c('v-checkbox',{attrs:{"dense":""},on:{"change":function($event){return _vm.update_permiso($event, item.permiso_global)}},model:{value:(item.permiso_global_checked),callback:function ($$v) {_vm.$set(item, "permiso_global_checked", $$v)},expression:"item.permiso_global_checked"}}):_vm._e()]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }